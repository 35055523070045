import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="equipment"></a><h2>Equipment</h2>
    <a id="goods-and-services"></a><h3>GOODS AND SERVICES</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <a id="table-goods-and-services"></a><p><b>Table: Goods and Services</b></p>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr valign="top">
          <td style={{
            "width": "30%"
          }}> <b>Adventuring Gear:</b>
            <table border="0" cellPadding="1" cellSpacing="0" style={{
              "width": "100%"
            }}>
              <tbody>
                <tr>
                  <th style={{
                    "width": "60%"
                  }}>Item</th>
                  <th style={{
                    "width": "20%"
                  }}>Cost</th>
                  <th style={{
                    "width": "20%"
                  }}>Weight</th>
                </tr>
                <tr className="odd-row">
                  <td>Backpack (empty)</td>
                  <td>2 gp</td>
                  <td>2 lb.<sup>1</sup></td>
                </tr>
                <tr>
                  <td>Barrel (empty)</td>
                  <td>2 gp</td>
                  <td>30 lb.</td>
                </tr>
                <tr className="odd-row">
                  <td>Basket (empty)</td>
                  <td>4 sp</td>
                  <td>1 lb.</td>
                </tr>
                <tr>
                  <td>Bedroll</td>
                  <td>1 sp</td>
                  <td>5 lb.<sup>1</sup></td>
                </tr>
                <tr className="odd-row">
                  <td>Bell</td>
                  <td>1 gp</td>
                  <td>&mdash;</td>
                </tr>
                <tr>
                  <td>Blanket, winter</td>
                  <td>5 sp</td>
                  <td>3 lb.<sup>1</sup></td>
                </tr>
                <tr className="odd-row">
                  <td>Block and tackle</td>
                  <td>5 gp</td>
                  <td>5 lb.</td>
                </tr>
                <tr>
                  <td>Bottle, wine glass</td>
                  <td>2 gp</td>
                  <td>&mdash;</td>
                </tr>
                <tr className="odd-row">
                  <td>Bucket (empty)</td>
                  <td>5 sp</td>
                  <td>2 lb.</td>
                </tr>
                <tr>
                  <td>Caltrops</td>
                  <td>1 gp</td>
                  <td>2 lb.</td>
                </tr>
                <tr className="odd-row">
                  <td>Candle</td>
                  <td>1 cp</td>
                  <td>&mdash;</td>
                </tr>
                <tr>
                  <td>Canvas (sq. yd.)</td>
                  <td>1 sp</td>
                  <td>1 lb.</td>
                </tr>
                <tr className="odd-row">
                  <td>Case, map or scroll</td>
                  <td>1 gp</td>
                  <td>1/2 lb.</td>
                </tr>
                <tr>
                  <td>Chain (10 ft.)</td>
                  <td>30 gp</td>
                  <td>2 lb.</td>
                </tr>
                <tr className="odd-row">
                  <td>Chalk, 1 piece</td>
                  <td>1 cp</td>
                  <td>&mdash;</td>
                </tr>
                <tr>
                  <td>Chest (empty)</td>
                  <td>2 gp</td>
                  <td>25 lb.</td>
                </tr>
                <tr className="odd-row">
                  <td>Crowbar</td>
                  <td>2 gp</td>
                  <td>5 lb.</td>
                </tr>
                <tr>
                  <td>Firewood (per day)</td>
                  <td>1 cp</td>
                  <td>20 lb.</td>
                </tr>
                <tr className="odd-row">
                  <td>Fishhook</td>
                  <td>1 sp</td>
                  <td>&mdash;</td>
                </tr>
                <tr>
                  <td>Fishing net, 25 sq. ft.</td>
                  <td>4 gp</td>
                  <td>5 lb.</td>
                </tr>
                <tr className="odd-row">
                  <td>Flask (empty)</td>
                  <td>3 cp</td>
                  <td>1-1/2 lb.</td>
                </tr>
                <tr>
                  <td>Flint and steel</td>
                  <td>1 gp</td>
                  <td>&mdash;</td>
                </tr>
                <tr className="odd-row">
                  <td>Grappling hook</td>
                  <td>1 gp</td>
                  <td>4 lb.</td>
                </tr>
                <tr>
                  <td>Hammer</td>
                  <td>5 sp</td>
                  <td>2 lb.</td>
                </tr>
                <tr className="odd-row">
                  <td>Ink (1 oz. vial)</td>
                  <td>8 gp</td>
                  <td>&mdash;</td>
                </tr>
                <tr>
                  <td>Inkpen</td>
                  <td>1 sp</td>
                  <td>&mdash;</td>
                </tr>
                <tr className="odd-row">
                  <td>Jug, clay</td>
                  <td>3 cp</td>
                  <td>9 lb.</td>
                </tr>
                <tr>
                  <td>Ladder, 10-foot</td>
                  <td>5 cp</td>
                  <td>20 lb.</td>
                </tr>
                <tr className="odd-row">
                  <td>Lamp, common</td>
                  <td>1 sp</td>
                  <td>1 lb.</td>
                </tr>
                <tr>
                  <td>Lantern, bullseye</td>
                  <td>12 gp</td>
                  <td>3 lb.</td>
                </tr>
                <tr className="odd-row">
                  <td>Lantern, hooded</td>
                  <td>7 gp</td>
                  <td>2 lb.</td>
                </tr>
                <tr>
                  <td>Lock</td>
                  <td> 
                  </td>
                  <td>1 lb.</td>
                </tr>
                <tr className="odd-row">
                  <td>
                    <div style={{
                      "marginLeft": "40px"
                    }}>Very simple</div>
                  </td>
                  <td>20 gp</td>
                  <td>1 lb.</td>
                </tr>
                <tr>
                  <td>
                    <div style={{
                      "marginLeft": "40px"
                    }}>Average</div>
                  </td>
                  <td>40 gp</td>
                  <td>1 lb.</td>
                </tr>
                <tr className="odd-row">
                  <td>
                    <div style={{
                      "marginLeft": "40px"
                    }}>Good</div>
                  </td>
                  <td>80 gp</td>
                  <td>1 lb.</td>
                </tr>
                <tr>
                  <td>
                    <div style={{
                      "marginLeft": "40px"
                    }}>Amazing</div>
                  </td>
                  <td>150 gp</td>
                  <td>1 lb.</td>
                </tr>
                <tr className="odd-row">
                  <td>Manacles</td>
                  <td>15 gp</td>
                  <td>2 lb.</td>
                </tr>
                <tr>
                  <td>Manacles, masterwork</td>
                  <td>50 gp</td>
                  <td>2 lb.</td>
                </tr>
                <tr className="odd-row">
                  <td>Mirror, small steel</td>
                  <td>10 gp</td>
                  <td>1/2 lb.</td>
                </tr>
                <tr>
                  <td>Mug/Tankard, clay</td>
                  <td>2 cp</td>
                  <td>1 lb.</td>
                </tr>
                <tr className="odd-row">
                  <td>Oil (1-pint flask)</td>
                  <td>1 sp</td>
                  <td>1 lb.</td>
                </tr>
                <tr>
                  <td>Paper (sheet)</td>
                  <td>4 sp</td>
                  <td>&mdash;</td>
                </tr>
                <tr className="odd-row">
                  <td>Parchment (sheet)</td>
                  <td>2 sp</td>
                  <td>&mdash;</td>
                </tr>
                <tr>
                  <td>Pick, miner&rsquo;s</td>
                  <td>3 gp</td>
                  <td>10 lb.</td>
                </tr>
                <tr className="odd-row">
                  <td>Pitcher, clay</td>
                  <td>2 cp</td>
                  <td>5 lb.</td>
                </tr>
                <tr>
                  <td>Piton</td>
                  <td>1 sp</td>
                  <td>1/2 lb.</td>
                </tr>
                <tr className="odd-row">
                  <td>Pole 10-foot</td>
                  <td>2 sp</td>
                  <td>8 lb.</td>
                </tr>
                <tr>
                  <td>Pot, iron</td>
                  <td>5 sp</td>
                  <td>10 lb.</td>
                </tr>
                <tr className="odd-row">
                  <td>Pouch, belt (empty)</td>
                  <td>1 gp</td>
                  <td>1/2 lb.<sup>1</sup></td>
                </tr>
                <tr>
                  <td>Ram, portable</td>
                  <td>10 gp</td>
                  <td>20 lb.</td>
                </tr>
                <tr className="odd-row">
                  <td>Rations, trail (per day)</td>
                  <td>5 sp</td>
                  <td>1 lb.<sup>1</sup></td>
                </tr>
                <tr>
                  <td>Rope, hempen (50 ft.)</td>
                  <td>1 gp</td>
                  <td>10 lb.</td>
                </tr>
                <tr className="odd-row">
                  <td>Rope, silk (50 ft.)</td>
                  <td>10 gp</td>
                  <td>5 lb.</td>
                </tr>
                <tr>
                  <td>Sack (empty)</td>
                  <td>1 sp</td>
                  <td>1/2 lb.<sup>1</sup></td>
                </tr>
                <tr className="odd-row">
                  <td>Sealing wax</td>
                  <td>1 gp</td>
                  <td>1 lb.</td>
                </tr>
                <tr>
                  <td>Sewing needle</td>
                  <td>5 sp</td>
                  <td>&mdash;</td>
                </tr>
                <tr className="odd-row">
                  <td>Signal whistle</td>
                  <td>8 sp</td>
                  <td>&mdash;</td>
                </tr>
                <tr>
                  <td>Signet ring</td>
                  <td>5 gp</td>
                  <td>&mdash;</td>
                </tr>
                <tr className="odd-row">
                  <td>Sledge</td>
                  <td>1 gp</td>
                  <td>10 lb.</td>
                </tr>
                <tr>
                  <td>Soap (per lb.)</td>
                  <td>5 sp</td>
                  <td>1 lb.</td>
                </tr>
                <tr className="odd-row">
                  <td>Spade or shovel</td>
                  <td>2 gp</td>
                  <td>8 lb.</td>
                </tr>
                <tr>
                  <td>Spyglass</td>
                  <td>1000 gp</td>
                  <td>1 lb.</td>
                </tr>
                <tr className="odd-row">
                  <td>Tent</td>
                  <td>10 gp</td>
                  <td>20 lb.<sup>1</sup></td>
                </tr>
                <tr>
                  <td>Torch</td>
                  <td>1 cp</td>
                  <td>1 lb.</td>
                </tr>
                <tr className="odd-row">
                  <td>Vial ink or potion</td>
                  <td>1 gp</td>
                  <td>1/10 lb.</td>
                </tr>
                <tr>
                  <td>Waterskin</td>
                  <td>1 gp</td>
                  <td>4 lb.<sup>1</sup></td>
                </tr>
                <tr className="odd-row">
                  <td>Whetstone</td>
                  <td>2 cp</td>
                  <td>1 lb.</td>
                </tr>
              </tbody>
            </table>
          </td>
          <td style={{
            "width": "5%"
          }}>&nbsp;</td>
          <td style={{
            "width": "30%"
          }}> <b>Special Substances and Items:</b>
            <table border="0" cellPadding="1" cellSpacing="0" style={{
              "width": "100%"
            }}>
              <tbody>
                <tr>
                  <th style={{
                    "width": "60%"
                  }}>Item</th>
                  <th style={{
                    "width": "20%"
                  }}>Cost</th>
                  <th style={{
                    "width": "20%"
                  }}>Weight</th>
                </tr>
                <tr className="odd-row">
                  <td>Acid (flask)</td>
                  <td>10 gp</td>
                  <td>1 lb.</td>
                </tr>
                <tr>
                  <td>Alchemist&rsquo;s fire (flask)</td>
                  <td>20 gp</td>
                  <td>1 lb.</td>
                </tr>
                <tr className="odd-row">
                  <td>Antitoxin (vial)</td>
                  <td>50 gp</td>
                  <td>&mdash;</td>
                </tr>
                <tr>
                  <td>Everburning torch</td>
                  <td>110 gp</td>
                  <td>1 lb.</td>
                </tr>
                <tr className="odd-row">
                  <td>Holy water (flask)</td>
                  <td>25 gp</td>
                  <td>1 lb.</td>
                </tr>
                <tr>
                  <td>Smokestick</td>
                  <td>20 gp</td>
                  <td>1/2 lb.</td>
                </tr>
                <tr className="odd-row">
                  <td>Sunrod</td>
                  <td>2 gp</td>
                  <td>1 lb.</td>
                </tr>
                <tr>
                  <td>Tanglefoot bag</td>
                  <td>50 gp</td>
                  <td>4 lb.</td>
                </tr>
                <tr className="odd-row">
                  <td>Thunderstone</td>
                  <td>30 gp</td>
                  <td>1 lb.</td>
                </tr>
                <tr>
                  <td className="last-row">Tindertwig</td>
                  <td className="last-row">1 gp</td>
                  <td className="last-row">&mdash;</td>
                </tr>
              </tbody>
            </table>
            <b>Tools and Skill Kits:</b>
            <table border="0" cellPadding="1" cellSpacing="0" style={{
              "width": "100%"
            }}>
              <tbody>
                <tr>
                  <th style={{
                    "width": "60%"
                  }}>Item</th>
                  <th style={{
                    "width": "20%"
                  }}>Cost</th>
                  <th style={{
                    "width": "20%"
                  }}>Weight</th>
                </tr>
                <tr className="odd-row">
                  <td>Alchemist&rsquo;s lab</td>
                  <td>500 gp</td>
                  <td>40 lb.</td>
                </tr>
                <tr>
                  <td>Artisan&rsquo;s tools</td>
                  <td>5 gp</td>
                  <td>5 lb.</td>
                </tr>
                <tr className="odd-row">
                  <td>Artisan&rsquo;s tools, masterwork</td>
                  <td>55 gp</td>
                  <td>5 lb.</td>
                </tr>
                <tr>
                  <td>Climber&rsquo;s kit</td>
                  <td>80 gp</td>
                  <td>5 lb.<sup>1</sup></td>
                </tr>
                <tr className="odd-row">
                  <td>Disguise kit</td>
                  <td>50 gp</td>
                  <td>8 lb.<sup>1</sup></td>
                </tr>
                <tr>
                  <td>Healer&rsquo;s kit</td>
                  <td>50 gp</td>
                  <td>1 lb.</td>
                </tr>
                <tr className="odd-row">
                  <td>Holly and mistletoe</td>
                  <td>&mdash;</td>
                  <td>&mdash;</td>
                </tr>
                <tr>
                  <td>Holy symbol, wooden</td>
                  <td>1 gp</td>
                  <td>&mdash;</td>
                </tr>
                <tr className="odd-row">
                  <td>Holy symbol, silver</td>
                  <td>25 gp</td>
                  <td>1 lb.</td>
                </tr>
                <tr>
                  <td>Hourglass</td>
                  <td>25 gp</td>
                  <td>1 lb.</td>
                </tr>
                <tr className="odd-row">
                  <td>Magnifying glass</td>
                  <td>100 gp</td>
                  <td>&mdash;</td>
                </tr>
                <tr>
                  <td>Musical instrument, common</td>
                  <td>5 gp</td>
                  <td>3 lb.<sup>1</sup></td>
                </tr>
                <tr className="odd-row">
                  <td>Musical instrument, masterwork</td>
                  <td>100 gp</td>
                  <td>3 lb.<sup>1</sup></td>
                </tr>
                <tr>
                  <td>Scale, merchant&rsquo;s</td>
                  <td>2 gp</td>
                  <td>1 lb.</td>
                </tr>
                <tr className="odd-row">
                  <td>Spell component pouch</td>
                  <td>5 gp</td>
                  <td>2 lb.</td>
                </tr>
                <tr>
                  <td>Spellbook, wizard&rsquo;s (blank)</td>
                  <td>15 gp</td>
                  <td>3 lb.</td>
                </tr>
                <tr className="odd-row">
                  <td>Thieves&rsquo; tools</td>
                  <td>30 gp</td>
                  <td>1 lb.</td>
                </tr>
                <tr>
                  <td>Thieves&rsquo; tools, masterwork</td>
                  <td>100 gp</td>
                  <td>2 lb.</td>
                </tr>
                <tr className="odd-row">
                  <td>Tool, masterwork</td>
                  <td>50 gp</td>
                  <td>1 lb.</td>
                </tr>
                <tr>
                  <td className="last-row">Water clock</td>
                  <td className="last-row">1000 gp</td>
                  <td className="last-row">200 lb.</td>
                </tr>
              </tbody>
            </table>
            <b>Clothing:</b>
            <table border="0" cellPadding="1" cellSpacing="0" style={{
              "width": "100%"
            }}>
              <tbody>
                <tr>
                  <th style={{
                    "width": "60%"
                  }}>Item</th>
                  <th style={{
                    "width": "20%"
                  }}>Cost</th>
                  <th style={{
                    "width": "20%"
                  }}>Weight</th>
                </tr>
                <tr className="odd-row">
                  <td>Artisan&rsquo;s outfit</td>
                  <td>1 gp</td>
                  <td>4 lb.<sup>1</sup></td>
                </tr>
                <tr>
                  <td>Cleric&rsquo;s vestments</td>
                  <td>5 gp</td>
                  <td>6 lb.<sup>1</sup></td>
                </tr>
                <tr className="odd-row">
                  <td>Cold weather outfit</td>
                  <td>8 gp</td>
                  <td>7 lb.<sup>1</sup></td>
                </tr>
                <tr>
                  <td>Courtier&rsquo;s outfit</td>
                  <td>30 gp</td>
                  <td>6 lb.<sup>1</sup></td>
                </tr>
                <tr className="odd-row">
                  <td>Entertainer&rsquo;s outfit</td>
                  <td>3 gp</td>
                  <td>4 lb.<sup>1</sup></td>
                </tr>
                <tr>
                  <td>Explorer&rsquo;s outfit</td>
                  <td>10 gp</td>
                  <td>8 lb.<sup>1</sup></td>
                </tr>
                <tr className="odd-row">
                  <td>Monk&rsquo;s outfit</td>
                  <td>5 gp</td>
                  <td>2 lb.<sup>1</sup></td>
                </tr>
                <tr>
                  <td>Noble&rsquo;s outfit</td>
                  <td>75 gp</td>
                  <td>10 lb.<sup>1</sup></td>
                </tr>
                <tr className="odd-row">
                  <td>Peasant&rsquo;s outfit</td>
                  <td>1 sp</td>
                  <td>2 lb.<sup>1</sup></td>
                </tr>
                <tr>
                  <td>Royal outfit</td>
                  <td>200 gp</td>
                  <td>15 lb.<sup>1</sup></td>
                </tr>
                <tr className="odd-row">
                  <td>Scholar&rsquo;s outfit</td>
                  <td>5 gp</td>
                  <td>6 lb.<sup>1</sup></td>
                </tr>
                <tr>
                  <td>Traveler&rsquo;s outfit</td>
                  <td>1 gp</td>
                  <td>5 lb.<sup>1</sup></td>
                </tr>
              </tbody>
            </table>
            <b>Food, Drink, and Lodging:</b>
            <table border="0" cellPadding="1" cellSpacing="0" style={{
              "width": "100%"
            }}>
              <tbody>
                <tr>
                  <th style={{
                    "width": "60%"
                  }}>Item</th>
                  <th style={{
                    "width": "20%"
                  }}>Cost</th>
                  <th style={{
                    "width": "20%"
                  }}>Weight</th>
                </tr>
                <tr className="odd-row">
                  <td>Ale</td>
                  <td>
                  </td>
                  <td>
                  </td>
                </tr>
                <tr>
                  <td>Gallon</td>
                  <td>2 sp</td>
                  <td>8 lb.</td>
                </tr>
                <tr className="odd-row">
                  <td>Mug</td>
                  <td>4 cp</td>
                  <td>1 lb.</td>
                </tr>
                <tr>
                  <td>Banquet (per person)</td>
                  <td>10 gp</td>
                  <td>&mdash;</td>
                </tr>
                <tr className="odd-row">
                  <td>Bread, per loaf</td>
                  <td>2 cp</td>
                  <td>1/2 lb.</td>
                </tr>
                <tr>
                  <td>Cheese, hunk of</td>
                  <td>1 sp</td>
                  <td>1/2 lb.</td>
                </tr>
                <tr className="odd-row">
                  <td>Inn stay (per day)</td>
                  <td>
                  </td>
                  <td>
                  </td>
                </tr>
                <tr>
                  <td>Good</td>
                  <td>2 gp</td>
                  <td>&mdash;</td>
                </tr>
                <tr className="odd-row">
                  <td>Common</td>
                  <td>5 sp</td>
                  <td>&mdash;</td>
                </tr>
                <tr>
                  <td>Poor</td>
                  <td>2 sp</td>
                  <td>&mdash;</td>
                </tr>
                <tr className="odd-row">
                  <td>Meals (per day)</td>
                  <td>
                  </td>
                  <td>
                  </td>
                </tr>
                <tr>
                  <td>Good</td>
                  <td>5 sp</td>
                  <td>&mdash;</td>
                </tr>
                <tr className="odd-row">
                  <td>Common</td>
                  <td>3 sp</td>
                  <td>&mdash;</td>
                </tr>
                <tr>
                  <td>Poor</td>
                  <td>1 sp</td>
                  <td>&mdash;</td>
                </tr>
                <tr className="odd-row">
                  <td>Meat, chunk of</td>
                  <td>3 sp</td>
                  <td>1/2 lb.</td>
                </tr>
                <tr>
                  <td>Wine</td>
                  <td>
                  </td>
                  <td>
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>Common (pitcher)</td>
                  <td>2 sp</td>
                  <td>6 lb.</td>
                </tr>
                <tr>
                  <td className="last-row">Fine (bottle)</td>
                  <td className="last-row">10 gp</td>
                  <td className="last-row">1-1/2 lb.</td>
                </tr>
              </tbody>
            </table>
          </td>
          <td style={{
            "width": "5%"
          }}>&nbsp;</td>
          <td style={{
            "width": "30%"
          }}> <b>Mounts and Related Gear:</b>
            <table border="0" cellPadding="1" cellSpacing="0" style={{
              "width": "100%"
            }}>
              <tbody>
                <tr>
                  <th style={{
                    "width": "60%"
                  }}>Item</th>
                  <th style={{
                    "width": "20%"
                  }}>Cost</th>
                  <th style={{
                    "width": "20%"
                  }}>Weight</th>
                </tr>
                <tr className="odd-row">
                  <td>Barding</td>
                  <td>
                  </td>
                  <td>
                  </td>
                </tr>
                <tr>
                  <td>Medium creature</td>
                  <td>x2</td>
                  <td>x1</td>
                </tr>
                <tr className="odd-row">
                  <td>Large creature</td>
                  <td>x4</td>
                  <td>x2</td>
                </tr>
                <tr>
                  <td>Bit and bridle</td>
                  <td>2 gp</td>
                  <td>1 lb.</td>
                </tr>
                <tr className="odd-row">
                  <td>Dog, guard</td>
                  <td>25 gp</td>
                  <td>&mdash;</td>
                </tr>
                <tr>
                  <td>Dog, riding</td>
                  <td>150 gp</td>
                  <td>&mdash;</td>
                </tr>
                <tr className="odd-row">
                  <td>Donkey or mule</td>
                  <td>8 gp</td>
                  <td>&mdash;</td>
                </tr>
                <tr>
                  <td>Feed (per day)</td>
                  <td>5 cp</td>
                  <td>10 lb.</td>
                </tr>
                <tr className="odd-row">
                  <td>Horse</td>
                  <td>
                  </td>
                  <td>
                  </td>
                </tr>
                <tr>
                  <td>Horse, heavy</td>
                  <td>200 gp</td>
                  <td>&mdash;</td>
                </tr>
                <tr className="odd-row">
                  <td>Horse, light</td>
                  <td>75 gp</td>
                  <td>&mdash;</td>
                </tr>
                <tr>
                  <td>Pony</td>
                  <td>30 gp</td>
                  <td>&mdash;</td>
                </tr>
                <tr className="odd-row">
                  <td>Warhorse, heavy</td>
                  <td>400 gp</td>
                  <td>&mdash;</td>
                </tr>
                <tr>
                  <td>Warhorse, light</td>
                  <td>150 gp</td>
                  <td>&mdash;</td>
                </tr>
                <tr className="odd-row">
                  <td>Warpony</td>
                  <td>100 gp</td>
                  <td>&mdash;</td>
                </tr>
                <tr>
                  <td>Saddle</td>
                  <td>
                  </td>
                  <td>
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>Military</td>
                  <td>20 gp</td>
                  <td>30 lb.</td>
                </tr>
                <tr>
                  <td>Pack</td>
                  <td>5 gp</td>
                  <td>15 lb.</td>
                </tr>
                <tr className="odd-row">
                  <td>Riding</td>
                  <td>10 gp</td>
                  <td>25 lb.</td>
                </tr>
                <tr>
                  <td>Saddle, Exotic</td>
                  <td>
                  </td>
                  <td>
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>Military</td>
                  <td>60 gp</td>
                  <td>40 lb.</td>
                </tr>
                <tr>
                  <td>Pack</td>
                  <td>15 gp</td>
                  <td>20 lb.</td>
                </tr>
                <tr className="odd-row">
                  <td>Riding</td>
                  <td>30 gp</td>
                  <td>30 lb.</td>
                </tr>
                <tr>
                  <td>Saddlebags</td>
                  <td>4 gp</td>
                  <td>8 lb.</td>
                </tr>
                <tr className="odd-row">
                  <td>Stabling (per day)</td>
                  <td>5 sp</td>
                  <td>&mdash;</td>
                </tr>
              </tbody>
            </table>
            <b>Transport:</b>
            <table border="0" cellPadding="1" cellSpacing="0" style={{
              "width": "100%"
            }}>
              <tbody>
                <tr>
                  <th style={{
                    "width": "60%"
                  }}>Item</th>
                  <th style={{
                    "width": "20%"
                  }}>Cost</th>
                  <th style={{
                    "width": "20%"
                  }}>Weight</th>
                </tr>
                <tr className="odd-row">
                  <td>Carriage</td>
                  <td>100 gp</td>
                  <td>600 lb.</td>
                </tr>
                <tr>
                  <td>Cart</td>
                  <td>15 gp</td>
                  <td>200 lb.</td>
                </tr>
                <tr className="odd-row">
                  <td>Galley</td>
                  <td>30,000 gp</td>
                  <td>&mdash;</td>
                </tr>
                <tr>
                  <td>Keelboat</td>
                  <td>3,000 gp</td>
                  <td>&mdash;</td>
                </tr>
                <tr className="odd-row">
                  <td>Longship</td>
                  <td>10,000 gp</td>
                  <td>&mdash;</td>
                </tr>
                <tr>
                  <td>Rowboat</td>
                  <td>50 gp</td>
                  <td>100 lb.</td>
                </tr>
                <tr className="odd-row">
                  <td>Oar</td>
                  <td>2 gp</td>
                  <td>10 lb.</td>
                </tr>
                <tr>
                  <td>Sailing ship</td>
                  <td>10,000 gp</td>
                  <td>&mdash;</td>
                </tr>
                <tr className="odd-row">
                  <td>Sled</td>
                  <td>20 gp</td>
                  <td>300 lb.</td>
                </tr>
                <tr>
                  <td>Wagon</td>
                  <td>35 gp</td>
                  <td>400 lb.</td>
                </tr>
                <tr className="odd-row">
                  <td>Warship</td>
                  <td>25,000 gp</td>
                  <td>&mdash;</td>
                </tr>
              </tbody>
            </table>
            <b>Spellcasting and Services:</b>
            <table border="0" cellPadding="1" cellSpacing="0" style={{
              "width": "100%"
            }}>
              <tbody>
                <tr>
                  <th style={{
                    "width": "60%"
                  }}>Service</th>
                  <th style={{
                    "width": "40%"
                  }}>Cost</th>
                </tr>
                <tr className="odd-row">
                  <td>Coach cab</td>
                  <td>3 cp per mile</td>
                </tr>
                <tr>
                  <td>Hireling, trained</td>
                  <td>3 sp per day</td>
                </tr>
                <tr className="odd-row">
                  <td>Hireling, untrained</td>
                  <td>1 sp per day</td>
                </tr>
                <tr>
                  <td>Messenger</td>
                  <td>2 cp per mile</td>
                </tr>
                <tr className="odd-row">
                  <td>Road or gate toll</td>
                  <td>1 cp</td>
                </tr>
                <tr>
                  <td>Ship&rsquo;s passage</td>
                  <td>1 sp per mile</td>
                </tr>
                <tr className="odd-row">
                  <td>Spell, 0-level</td>
                  <td>Caster level x 5 gp<sup>2</sup></td>
                </tr>
                <tr>
                  <td>Spell, 1st-level</td>
                  <td>Caster level x 10 gp<sup>2</sup></td>
                </tr>
                <tr className="odd-row">
                  <td>Spell, 2nd-level</td>
                  <td>Caster level x 20 gp<sup>2</sup></td>
                </tr>
                <tr>
                  <td>Spell, 3rd-level</td>
                  <td>Caster level x 30 gp<sup>2</sup></td>
                </tr>
                <tr className="odd-row">
                  <td>Spell, 4th-level</td>
                  <td>Caster level x 40 gp<sup>2</sup></td>
                </tr>
                <tr>
                  <td>Spell, 5th-level</td>
                  <td>Caster level x 50 gp<sup>2</sup></td>
                </tr>
                <tr className="odd-row">
                  <td>Spell, 6th-level</td>
                  <td>Caster level x 60 gp<sup>2</sup></td>
                </tr>
                <tr>
                  <td>Spell, 7th-level</td>
                  <td>Caster level x 70 gp<sup>2</sup></td>
                </tr>
                <tr className="odd-row">
                  <td>Spell, 8th-level</td>
                  <td>Caster level x 80 gp<sup>2</sup></td>
                </tr>
                <tr>
                  <td className="last-row">Spell, 9th-level</td>
                  <td className="last-row">Caster level x 90 gp<sup>2</sup></td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
    <p>&mdash; <i>No weight, or no weight worth noting.</i>
      <p>{`1 `}<i>{`These items weigh one-quarter this amount when made for Small
characters. Containers for Small characters also carry one-quarter the
normal amount.`}</i></p>
      <p>{`2 `}<i>{`See spell description for additional costs. If the additional
costs put the spell`}{`’`}{`s total cost above 3,000 gp, that spell is
not
generally available.`}</i></p>
    </p>
    <a id="adventuring-gear"></a><h5>ADVENTURING GEAR</h5>
    <p className="initial">Few of the pieces of adventuring gear found on
Table: Goods and Services are described below, along with any special
benefits they confer on the user (&ldquo;you&rdquo;).</p>
    <a id="caltrops"></a><p><b>Caltrops</b>: A caltrop is a four-pronged iron spike
crafted so that one prong faces up no matter how the caltrop comes to
rest. You scatter caltrops on the ground in the hope that your enemies
step on them or are at least forced to slow down to avoid them. One 2-
pound bag of caltrops covers an area 5 feet square.</p>
    <p>Each time a creature moves into an area covered by caltrops
(or spends a round fighting while standing in such an area), it might
step on one. The caltrops make an attack roll (base attack bonus +0)
against the creature. For this attack, the creature&rsquo;s shield,
armor,
and deflection bonuses do not count. If the creature is wearing shoes
or other footwear, it gets a +2 armor bonus to AC. If the caltrops
succeed on the attack, the creature has stepped on one. The caltrop
deals 1 point of damage, and the creature&rsquo;s speed is reduced by
one-half because its foot is wounded. This movement penalty lasts for
24 hours, or until the creature is successfully treated with a DC 15
Heal check, or until it receives at least 1 point of magical curing. A
charging or running creature must immediately stop if it steps on a
caltrop. Any creature moving at half speed or slower can pick its way
through a bed of caltrops with no trouble.</p>
    <p>Caltrops may not be effective against unusual opponents.</p>
    <a id="candle"></a><p><b>Candle</b>: A candle dimly illuminates a 5-foot radius and
burns for 1 hour.</p>
    <a id="chain"></a><p><b>Chain</b>: Chain has hardness 10 and 5 hit points. It can
be burst with a DC 26 Strength check.</p>
    <a id="crowbar"></a><p><b>Crowbar</b>: A crowbar it grants a +2 circumstance bonus on
Strength checks made for such purposes. If used in combat, treat a
crowbar as a one-handed improvised weapon that deals bludgeoning damage
equal to that of a club of its size.</p>
    <a id="flint-and-steel"></a><p><b>Flint and Steel</b>: Lighting a torch with flint and steel
is a full-round action, and lighting any other fire with them takes at
least that long.</p>
    <a id="grappling-hook"></a><p><b>Grappling Hook</b>: Throwing a grappling hook successfully
requires a Use Rope check (DC 10, +2 per 10 feet of distance thrown).</p>
    <a id="hammer"></a><p><b>Hammer</b>: If a hammer is used in combat, treat it as a
one-handed improvised weapon that deals bludgeoning damage equal to
that of a spiked gauntlet of its size.</p>
    <a id="ink"></a><p><b>Ink</b>: This is black ink. You can buy ink in other
colors, but it costs twice as much.</p>
    <a id="jug-clay"></a><p><b>Jug, Clay</b>: This basic ceramic jug is fitted with a
stopper and holds 1 gallon of liquid.</p>
    <a id="lamp-common"></a><p><b>Lamp, Common</b>: A lamp clearly illuminates a 15-foot
radius, provides shadowy illumination out to a 30-foot radius, and
burns for 6 hours on a pint of oil. You can carry a lamp in one hand. </p>
    <a id="lantern-bullseye"></a><p><b>Lantern, Bullseye</b>: A bullseye lantern provides clear
illumination in a 60-foot cone and shadowy illumination in a 120-foot
cone. It burns for 6 hours on a pint of oil. You can carry a bullseye
lantern in one hand.</p>
    <a id="lantern-hooded"></a><p><b>Lantern, Hooded</b>: A hooded lantern clearly illuminates a
30-foot radius and provides shadowy illumination in a 60-foot radius.
It burns for 6 hours on a pint of oil. You can carry a hooded lantern
in one hand.</p>
    <a id="lock"></a><p><b>Lock</b>: The DC to open a lock with the Open Lock skill
depends on the lock&rsquo;s quality: simple (DC 20), average (DC 25),
good (DC 30), or superior (DC 40).</p>
    <a id="manacles-and-manacles-masterwork"></a><p><b>Manacles and Manacles, Masterwork</b>: Manacles can bind a
Medium creature. A manacled creature can use the Escape Artist skill to
slip free (DC 30, or DC 35 for masterwork manacles). Breaking the
manacles requires a Strength check (DC 26, or DC 28 for masterwork
manacles). Manacles have hardness 10 and 10 hit points.</p>
    <p>Most manacles have locks; add the cost of the lock you want to
the cost of the manacles.</p>
    <p>For the same cost, you can buy manacles for a Small creature.
For a Large creature, manacles cost ten times the indicated amount, and
for a Huge creature, one hundred times this amount. Gargantuan,
Colossal, Tiny, Diminutive, and Fine creatures can be held only by
specially made manacles.</p>
    <a id="oil"></a><p><b>Oil</b>: A pint of oil burns for 6 hours in a lantern. You
can use a flask of oil as a splash weapon. Use the rules for
alchemist&rsquo;s fire, except that it takes a full round action to
prepare a
flask with a fuse. Once it is thrown, there is a 50% chance of the
flask igniting successfully.</p>
    <p>You can pour a pint of oil on the ground to cover an area 5
feet square, provided that the surface is smooth. If lit, the oil burns
for 2 rounds and deals 1d3 points of fire damage to each creature in
the area.</p>
    <a id="ram-portable"></a><p><b>Ram, Portable</b>: This iron-shod wooden beam gives you a
+2 circumstance bonus on Strength checks made to break open a door and
it allows a second person to help you without having to roll,
increasing your bonus by 2.</p>
    <a id="rope-hempen"></a><p><b>Rope, Hempen</b>: This rope has 2 hit points and can be
burst with a DC 23 Strength check.</p>
    <a id="rope-silk"></a><p><b>Rope, Silk</b>: This rope has 4 hit points and can be burst
with a DC 24 Strength check. It is so supple that it provides a +2
circumstance bonus on Use Rope checks.</p>
    <a id="spuglass"></a><p><b>Spyglass</b>: Objects viewed through a spyglass are
magnified to twice their size.</p>
    <a id="torch"></a><p><b>Torch</b>: A torch burns for 1 hour, clearly illuminating a
20-foot radius and providing shadowy illumination out to a 40- foot
radius. If a torch is used in combat, treat it as a one-handed
improvised weapon that deals bludgeoning damage equal to that of a
gauntlet of its size, plus 1 point of fire damage.</p>
    <a id="vial"></a><p><b>Vial</b>: A vial holds 1 ounce of liquid. The stoppered
container usually is no more than 1 inch wide and 3 inches high.</p>
    <a id="special-substances-and-items"></a><h5>SPECIAL SUBSTANCES AND ITEMS</h5>
    <p className="initial">Any of these substances except for the
everburning torch and holy water can be made by a character with the
Craft (alchemy) skill.</p>
    <a id="acid"></a><p><b>Acid</b>: You can throw a flask of acid as a splash weapon.
Treat this attack as a ranged touch attack with a range increment of 10
feet. A direct hit deals 1d6 points of acid damage. Every creature
within 5 feet of the point where the acid hits takes 1 point of acid
damage from the splash.</p>
    <a id="alchemists-fire"></a><p><b>Alchemist&rsquo;s Fire</b>: You can throw a flask of
alchemist&rsquo;s
fire as a splash weapon. Treat this attack as a ranged touch attack
with a range increment of 10 feet.</p>
    <p>A direct hit deals 1d6 points of fire damage. Every creature
within 5 feet of the point where the flask hits takes 1 point of fire
damage from the splash. On the round following a direct hit, the target
takes an additional 1d6 points of damage. If desired, the target can
use a full-round action to attempt to extinguish the flames before
taking this additional damage. Extinguishing the flames requires a DC
15 Reflex save. Rolling on the ground provides the target a +2 bonus on
the save. Leaping into a lake or magically extinguishing the flames
automatically smothers the fire.</p>
    <a id="antitoxin"></a><p><b>Antitoxin</b>: If you drink antitoxin, you get a +5
alchemical bonus on Fortitude saving throws against poison for 1 hour.</p>
    <a id="everburning-torch"></a><p><b>Everburning Torch</b>: This otherwise normal torch has a
continual flame spell cast upon it. An everburning torch clearly
illuminates a 20-foot radius and provides shadowy illumination out to a
40-foot radius.</p>
    <a id="holy-water"></a><p><b>Holy Water</b>: Holy water damages undead creatures and
evil outsiders almost as if it were acid. A flask of holy water can be
thrown as a splash weapon. Treat this attack as a ranged touch attack
with a range increment of 10 feet. A flask breaks if thrown against the
body of a corporeal creature, but to use it against an incorporeal
creature, you must open the flask and pour the holy water out onto the
target. Thus, you can douse an incorporeal creature with holy water
only if you are adjacent to it. Doing so is a ranged touch attack that
does not provoke attacks of opportunity.</p>
    <p>A direct hit by a flask of holy water deals 2d4 points of
damage to an undead creature or an evil outsider. Each such creature
within 5 feet of the point where the flask hits takes 1 point of damage
from the splash.</p>
    <p>Temples to good deities sell holy water at cost (making no
profit).</p>
    <a id="smokestick"></a><p><b>Smokestick</b>: This alchemically treated wooden stick
instantly creates thick, opaque smoke when ignited. The smoke fills a
10- foot cube (treat the effect as a fog cloud spell, except that a
moderate or stronger wind dissipates the smoke in 1 round). The stick
is consumed after 1 round, and the smoke dissipates naturally.</p>
    <a id="sunrod"></a><p><b>Sunrod</b>: This 1-foot-long, gold-tipped, iron rod glows
brightly when struck. It clearly illuminates a 30-foot radius and
provides shadowy illumination in a 60-foot radius. It glows for 6
hours, after which the gold tip is burned out and worthless.</p>
    <a id="tanglefoot-bag"></a><p><b>Tanglefoot Bag</b>: When you throw a tanglefoot bag at a
creature (as a ranged touch attack with a range increment of 10 feet),
the bag comes apart and the goo bursts out, entangling the target and
then becoming tough and resilient upon exposure to air. An entangled
creature takes a &ndash;2 penalty on attack rolls and a &ndash;4
penalty to
Dexterity and must make a DC 15 Reflex save or be glued to the floor,
unable to move. Even on a successful save, it can move only at half
speed. Huge or larger creatures are unaffected by a tanglefoot bag. A
flying creature is not stuck to the floor, but it must make a DC 15
Reflex save or be unable to fly (assuming it uses its wings to fly) and
fall to the ground. A tanglefoot bag does not function underwater.</p>
    <p>A creature that is glued to the floor (or unable to fly) can
break free by making a DC 17 Strength check or by dealing 15 points of
damage to the goo with a slashing weapon. A creature trying to scrape
goo off itself, or another creature assisting, does not need to make an
attack roll; hitting the goo is automatic, after which the creature
that hit makes a damage roll to see how much of the goo was scraped
off. Once free, the creature can move (including flying) at half speed.
A character capable of spellcasting who is bound by the goo must make a
DC 15 Concentration check to cast a spell. The goo becomes brittle and
fragile after 2d4 rounds, cracking apart and losing its effectiveness.
An application of universal solvent to a stuck creature dissolves the
alchemical goo immediately.</p>
    <a id="thunderstone"></a><p><b>Thunderstone</b>: You can throw this stone as a ranged
attack with a range increment of 20 feet. When it strikes a hard
surface (or is struck hard), it creates a deafening bang that is
treated as a sonic attack. Each creature within a 10-foot-radius spread
must make a DC 15 Fortitude save or be deafened for 1 hour. A deafened
creature, in addition to the obvious effects, takes a &ndash;4 penalty
on
initiative and has a 20% chance to miscast and lose any spell with a
verbal component that it tries to cast.</p>
    <p>Since you don&rsquo;t need to hit a specific target, you can
simply
aim at a particular 5-foot square. Treat the target square as AC 5.</p>
    <a id="tindertwig"></a><p><b>Tindertwig</b>: The alchemical substance on the end of this
small, wooden stick ignites when struck against a rough surface.
Creating a flame with a tindertwig is much faster than creating a flame
with flint and steel (or a magnifying glass) and tinder. Lighting a
torch with a tindertwig is a standard action (rather than a full-round
action), and lighting any other fire with one is at least a standard
action.</p>
    <a id="tools-and-skill-kits"></a><h5>TOOLS AND SKILL KITS</h5>
    <a id="alchemists-lab"></a><p className="intial"><b>Alchemist&rsquo;s Lab</b>: An
alchemist&rsquo;s lab
always has the perfect tool for making alchemical items, so it provides
a +2 circumstance bonus on Craft (alchemy) checks. It has no bearing on
the costs related to the Craft (alchemy) skill. Without this lab, a
character with the Craft (alchemy) skill is assumed to have enough
tools to use the skill but not enough to get the +2 bonus that the lab
provides.</p>
    <a id="artistans-tools"></a><p><b>Artisan&rsquo;s Tools</b>: These special tools include the
items
needed to pursue any craft. Without them, you have to use improvised
tools (&ndash;2 penalty on Craft checks), if you can do the job at all.</p>
    <a id="artistans-tools-masterwork"></a><p><b>Artisan&rsquo;s Tools, Masterwork</b>: These tools serve
the same
purpose as artisan&rsquo;s tools (above), but masterwork
artisan&rsquo;s tools are
the perfect tools for the job, so you get a +2 circumstance bonus on
Craft checks made with them.</p>
    <a id="climbers-kit"></a><p><b>Climber&rsquo;s Kit</b>: This is the perfect tool for
climbing
and gives you a +2 circumstance bonus on Climb checks.</p>
    <a id="disguise-kit"></a><p><b>Disguise Kit</b>: The kit is the perfect tool for disguise
and provides a +2 circumstance bonus on Disguise checks. A disguise kit
is exhausted after ten uses.</p>
    <a id="healers-kit"></a><p><b>Healer&rsquo;s Kit</b>: It is the perfect tool for healing
and
provides a +2 circumstance bonus on Heal checks. A healer&rsquo;s kit
is
exhausted after ten uses.</p>
    <a id="holy-symbol-silver-or-wooden"></a><p><b>Holy Symbol, Silver or Wooden</b>: A holy symbol focuses
positive energy. A cleric or paladin uses it as the focus for his
spells and as a tool for turning undead. Each religion has its own holy
symbol.</p>
    <a id="unholy-symbol"></a><p><b>Unholy Symbols</b>: An unholy symbol is like a holy symbol
except that it focuses negative energy and is used by evil clerics (or
by neutral clerics who want to cast evil spells or command undead).</p>
    <a id="magnifying-glass"></a><p><b>Magnifying Glass</b>: This simple lens allows a closer look
at small objects. It is also useful as a substitute for flint and steel
when starting fires. Lighting a fire with a magnifying glass requires
light as bright as sunlight to focus, tinder to ignite, and at least a
full-round action. A magnifying glass grants a +2 circumstance bonus on
Appraise checks involving any item that is small or highly detailed.</p>
    <a id="musical-instrument-common-or-masterwork"></a><p><b>Musical Instrument, Common or Masterwork</b>: A masterwork
instrument grants a +2 circumstance bonus on Perform checks involving
its use.</p>
    <a id="scale-merchants"></a><p><b>Scale, Merchant&rsquo;s</b>: A scale grants a +2
circumstance
bonus on Appraise checks involving items that are valued by weight,
including anything made of precious metals.</p>
    <a id="spell-component-pouch"></a><p><b>Spell Component Pouch</b>: A spellcaster with a spell
component pouch is assumed to have all the material components and
focuses needed for spellcasting, except for those components that have
a specific cost, divine focuses, and focuses that wouldn&rsquo;t fit in
a
pouch.</p>
    <a id="spellbook-wizards"></a><p><b>Spellbook, Wizard&rsquo;s (Blank)</b>: A spellbook has 100
pages
of parchment, and each spell takes up one page per spell level (one
page each for 0-level spells).</p>
    <a id="thieves-tools"></a><p><b>Thieves&rsquo; Tools</b>: This kit contains the tools you
need to
use the Disable Device and Open Lock skills. Without these tools, you
must improvise tools, and you take a &ndash;2 circumstance penalty on
Disable
Device and Open Locks checks.</p>
    <a id="thieves-tools-masterwork"></a><p><b>Thieves&rsquo; Tools, Masterwork</b>: This kit contains
extra
tools and tools of better make, which grant a +2 circumstance bonus on
Disable Device and Open Lock checks.</p>
    <a id="tool-masterwork"></a><p><b>Tool, Masterwork</b>: This well-made item is the perfect
tool for the job. It grants a +2 circumstance bonus on a related skill
check (if any). Bonuses provided by multiple masterwork items used
toward the same skill check do not stack.</p>
    <a id="water-clock"></a><p><b>Water Clock</b>: This large, bulky contrivance gives the
time accurate to within half an hour per day since it was last set. It
requires a source of water, and it must be kept still because it marks
time by the regulated flow of droplets of water.</p>
    <a id="clothing"></a><h5>CLOTHING</h5>
    <a id="artisans-outfit"></a><p className="initial"><b>Artisan&rsquo;s Outfit</b>: This outfit
includes
a shirt with buttons, a skirt or pants with a drawstring, shoes, and
perhaps a cap or hat. It may also include a belt or a leather or cloth
apron for carrying tools.</p>
    <a id="clerics-vestments"></a><p><b>Cleric&rsquo;s Vestments</b>: These ecclesiastical clothes
are
for performing priestly functions, not for adventuring.</p>
    <a id="cold-weather-outfit"></a><p><b>Cold Weather Outfit</b>: A cold weather outfit includes a
wool coat, linen shirt, wool cap, heavy cloak, thick pants or skirt,
and boots. This outfit grants a +5 circumstance bonus on Fortitude
saving throws against exposure to cold weather.</p>
    <a id="courtiers-outfit"></a><p><b>Courtier&rsquo;s Outfit</b>: This outfit includes fancy,
tailored
clothes in whatever fashion happens to be the current style in the
courts of the nobles. Anyone trying to influence nobles or courtiers
while wearing street dress will have a hard time of it (&ndash;2
penalty on
Charisma-based skill checks to influence such individuals). If you wear
this outfit without jewelry (costing an additional 50 gp), you look
like an out-of-place commoner.</p>
    <a id="entertainers-outfit"></a><p><b>Entertainer&rsquo;s Outfit</b>: This set of flashy, perhaps
even
gaudy, clothes is for entertaining. While the outfit looks whimsical,
its practical design lets you tumble, dance, walk a tightrope, or just
run (if the audience turns ugly).</p>
    <a id="explorers-outfit"></a><p><b>Explorer&rsquo;s Outfit</b>: This is a full set of clothes
for
someone who never knows what to expect. It includes sturdy boots,
leather breeches or a skirt, a belt, a shirt (perhaps with a vest or
jacket), gloves, and a cloak. Rather than a leather skirt, a leather
overtunic may be worn over a cloth skirt. The clothes have plenty of
pockets (especially the cloak). The outfit also includes any extra
items you might need, such as a scarf or a wide-brimmed hat.</p>
    <a id="monks-outfit"></a><p><b>Monk&rsquo;s Outfit</b>: This simple outfit includes
sandals,
loose breeches, and a loose shirt, and is all bound together with
sashes. The outfit is designed to give you maximum mobility, and
it&rsquo;s
made of high-quality fabric. You can hide small weapons in pockets
hidden in the folds, and the sashes are strong enough to serve as short
ropes.</p>
    <a id="nobles-outfit"></a><p><b>Noble&rsquo;s Outfit</b>: This set of clothes is designed
specifically to be expensive and to show it. Precious metals and gems
are worked into the clothing. To fit into the noble crowd, every
would-be noble also needs a signet ring (see Adventuring Gear, above)
and jewelry (worth at least 100 gp).</p>
    <a id="peasants-outfit"></a><p><b>Peasant&rsquo;s Outfit</b>: This set of clothes consists of
a
loose shirt and baggy breeches, or a loose shirt and skirt or
overdress. Cloth wrappings are used for shoes.</p>
    <a id="royal-outfit"></a><p><b>Royal Outfit</b>: This is just the clothing, not the royal
scepter, crown, ring, and other accoutrements. Royal clothes are
ostentatious, with gems, gold, silk, and fur in abundance.</p>
    <a id="scholars-outfit"></a><p><b>Scholar&rsquo;s Outfit</b>: Perfect for a scholar, this
outfit
includes a robe, a belt, a cap, soft shoes, and possibly a cloak.</p>
    <a id="travelers-outfit"></a><p><b>Traveler&rsquo;s Outfit</b>: This set of clothes consists
of
boots, a wool skirt or breeches, a sturdy belt, a shirt (perhaps with a
vest or jacket), and an ample cloak with a hood.</p>
    <a id="food-drink-and-lodging"></a><h5>FOOD, DRINK, AND LODGING</h5>
    <a id="inn"></a><p className="initial"><b>Inn</b>: Poor accommodations at an inn
amount to a place on the floor near the hearth. Common accommodations
consist of a place on a raised, heated floor, the use of a blanket and
a pillow. Good accommodations consist of a small, private room with one
bed, some amenities, and a covered chamber pot in the corner.</p>
    <a id="meals"></a><p><b>Meals</b>: Poor meals might be composed of bread, baked
turnips, onions, and water. Common meals might consist of bread,
chicken stew, carrots, and watered-down ale or wine. Good meals might
be composed of bread and pastries, beef, peas, and ale or wine.</p>
    <a id="mounts-and-related-gear"></a><h5>MOUNTS AND RELATED GEAR</h5>
    <a id="barding-medium-creature-and-large-creature"></a><p className="initial"><b>Barding, Medium Creature and Large Creature</b>:
Barding is a type of armor that covers the head, neck, chest, body, and
possibly legs of a horse or other mount. Barding made of medium or
heavy armor provides better protection than light barding, but at the
expense of speed. Barding can be made of any of the armor types found
on Table: Armor and Shields.</p>
    <p>Armor for a horse (a Large nonhumanoid creature) costs four
times as much as armor for a human (a Medium humanoid creature) and
also weighs twice as much as the armor found on Table: Armor and
Shields (see Armor for Unusual Creatures). If the barding is for a pony
or other Medium mount, the cost is only double, and the weight is the
same as for Medium armor worn by a humanoid. Medium or heavy barding
slows a mount that wears it, as shown on the table below.</p>
    <a id="table-barding"></a><table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th>&nbsp;</th>
          <th colSpan="3" className="center">Base Speed</th>
        </tr>
        <tr>
          <th style={{
            "width": "100px"
          }}>Barding</th>
          <th style={{
            "width": "75px"
          }}>(40 ft.)</th>
          <th style={{
            "width": "75px"
          }}>(50 ft.)</th>
          <th style={{
            "width": "75px"
          }}>(60 ft.)</th>
        </tr>
        <tr className="odd-row">
          <td>Medium</td>
          <td>30 ft.</td>
          <td>35 ft.</td>
          <td>40 ft.</td>
        </tr>
        <tr>
          <td className="last-row">Heavy</td>
          <td className="last-row">30 ft.<sup>1</sup></td>
          <td className="last-row">35 ft.<sup>1</sup></td>
          <td className="last-row">40 ft.<sup>1</sup></td>
        </tr>
        <tr>
          <td colSpan="4">1 <i>A mount wearing heavy armor moves at
only triple its normal speed
when running instead of quadruple.</i></td>
        </tr>
      </tbody>
    </table>
    <p>Flying mounts can&rsquo;t fly in medium or heavy barding.</p>
    <p>Removing and fitting barding takes five times as long as the
figures given on Table: Donning Armor. A barded animal cannot be used
to carry any load other than the rider and normal saddlebags.</p>
    <a id="dog-riding"></a><p><b>Dog, Riding</b>: This Medium dog is specially trained to
carry a Small humanoid rider. It is brave in combat like a warhorse.
You take no damage when you fall from a riding dog.</p>
    <a id="donkey-or-mule"></a><p><b>Donkey or Mule</b>: Donkeys and mules are stolid in the
face of danger, hardy, surefooted, and capable of carrying heavy loads
over vast distances. Unlike a horse, a donkey or a mule is willing
(though not eager) to enter dungeons and other strange or threatening
places.</p>
    <a id="feed"></a><p><b>Feed</b>: Horses, donkeys, mules, and ponies can graze to
sustain themselves, but providing feed for them is much better. If you
have a riding dog, you have to feed it at least some meat.</p>
    <a id="horse"></a><p><b>Horse</b>: A horse (other than a pony) is suitable as a
mount for a human, dwarf, elf, half-elf, or half-orc. A pony is smaller
than a horse and is a suitable mount for a gnome or halfling.</p>
    <p>Warhorses and warponies can be ridden easily into combat.
Light horses, ponies, and heavy horses are hard to control in combat.</p>
    <a id="saddle-exotic"></a><p><b>Saddle, Exotic</b>: An exotic saddle is like a normal
saddle of the same sort except that it is designed for an unusual
mount. Exotic saddles come in military, pack, and riding styles.</p>
    <a id="saddle-military"></a><p><b>Saddle, Military</b>: A military saddle braces the rider,
providing a +2 circumstance bonus on Ride checks related to staying in
the saddle. If you&rsquo;re knocked unconscious while in a military
saddle,
you have a 75% chance to stay in the saddle (compared to 50% for a
riding saddle).</p>
    <a id="saddle-pack"></a><p><b>Saddle, Pack</b>: A pack saddle holds gear and supplies,
but not a rider. It holds as much gear as the mount can carry.</p>
    <a id="saddle-riding"></a><p><b>Saddle, Riding</b>: The standard riding saddle supports a
rider.</p>
    <a id="transport"></a><h5>TRANSPORT</h5>
    <a id="carriage"></a><p className="initial"><b>Carriage</b>: This four-wheeled vehicle can
transport as many as four people within an enclosed cab, plus two
drivers. In general, two horses (or other beasts of burden) draw it. A
carriage comes with the harness needed to pull it.</p>
    <a id="cart"></a><p><b>Cart</b>: This two-wheeled vehicle can be drawn by a single
horse (or other beast of burden). It comes with a harness.</p>
    <a id="galley"></a><p><b>Galley</b>: This three-masted ship has seventy oars on
either side and requires a total crew of 200. A galley is 130 feet long
and 20 feet wide, and it can carry 150 tons of cargo or 250 soldiers.
For 8,000 gp more, it can be fitted with a ram and castles with firing
platforms fore, aft, and amidships. This ship cannot make sea voyages
and sticks to the coast. It moves about 4 miles per hour when being
rowed or under sail.</p>
    <a id="keelboat"></a><p><b>Keelboat</b>: This 50- to 75-foot-long ship is 15 to 20
feet wide and has a few oars to supplement its single mast with a
square sail. It has a crew of eight to fifteen and can carry 40 to 50
tons of cargo or 100 soldiers. It can make sea voyages, as well as sail
down rivers (thanks to its flat bottom). It moves about 1 mile per hour.</p>
    <a id="longship"></a><p><b>Longship</b>: This 75-foot-long ship with forty oars
requires a total crew of 50. It has a single mast and a square sail,
and it can carry 50 tons of cargo or 120 soldiers. A longship can make
sea voyages. It moves about 3 miles per hour when being rowed or under
sail.</p>
    <a id="rowboat"></a><p><b>Rowboat</b>: This 8- to 12-foot-long boat holds two or
three Medium passengers. It moves about 1-1/2 miles per hour.</p>
    <p><b>Sailing Ship</b>: This larger, seaworthy ship is 75 to 90
feet long and 20 feet wide and has a crew of 20. It can carry 150 tons
of cargo. It has square sails on its two masts and can make sea
voyages. It moves about 2 miles per hour.</p>
    <a id="sled"></a><p><b>Sled</b>: This is a wagon on runners for moving through
snow and over ice. In general, two horses (or other beasts of burden)
draw it. A sled comes with the harness needed to pull it.</p>
    <a id="wagon"></a><p><b>Wagon</b>: This is a four-wheeled, open vehicle for
transporting heavy loads. In general, two horses (or other beasts of
burden) draw it. A wagon comes with the harness needed to pull it.</p>
    <a id="warship"></a><p><b>Warship</b>: This 100-foot-long ship has a single mast,
although oars can also propel it. It has a crew of 60 to 80 rowers.
This ship can carry 160 soldiers, but not for long distances, since
there isn&rsquo;t room for supplies to support that many people. The
warship
cannot make sea voyages and sticks to the coast. It is not used for
cargo. It moves about 2-1/2 miles per hour when being rowed or under
sail.</p>
    <a id="spellcasting-and-services"></a><h5>SPELLCASTING AND SERVICES</h5>
    <p className="initial">Sometimes the best solution for a problem is
to hire someone else to take care of it.</p>
    <a id="coach-cab"></a><p><b>Coach Cab</b>: The price given is for a ride in a coach
that transports people (and light cargo) between towns. For a ride in a
cab that transports passengers within a city, 1 copper piece usually
takes you anywhere you need to go.</p>
    <a id="hireling-trained"></a><p><b>Hireling, Trained</b>: The amount given is the typical
daily wage for mercenary warriors, masons, craftsmen, scribes,
teamsters, and other trained hirelings. This value represents a minimum
wage; many such hirelings require significantly higher pay.</p>
    <a id="hireling-untrained"></a><p><b>Hireling, Untrained</b>: The amount shown is the typical
daily wage for laborers, porters, cooks, maids, and other menial
workers.</p>
    <a id="messenger"></a><p><b>Messenger</b>: This entry includes horse-riding messengers
and runners. Those willing to carry a message to a place they were
going anyway may ask for only half the indicated amount.</p>
    <a id="road-or-gate-toll"></a><p><b>Road or Gate Toll</b>: A toll is sometimes charged to cross
a well-trodden, well-kept, and well-guarded road to pay for patrols on
it and for its upkeep. Occasionally, a large walled city charges a toll
to enter or exit (or sometimes just to enter).</p>
    <a id="ships-passage"></a><p><b>Ship&rsquo;s Passage</b>: Most ships do not specialize in
passengers, but many have the capability to take a few along when
transporting cargo. Double the given cost for creatures larger than
Medium or creatures that are otherwise difficult to bring aboard a ship.</p>
    <a id="spell"></a><p><b>Spell</b>: The indicated amount is how much it costs to get
a spellcaster to cast a spell for you. This cost assumes that you can
go to the spellcaster and have the spell cast at his or her convenience
(generally at least 24 hours later, so that the spellcaster has time to
prepare the spell in question). If you want to bring the spellcaster
somewhere to cast a spell you need to negotiate with him or her, and
the default answer is no.</p>
    <p>The cost given is for a spell with no cost for a material
component or focus component and no XP cost. If the spell includes a
material component, add the cost of that component to the cost of the
spell. If the spell has a focus component (other than a divine focus),
add 1/10 the cost of that focus to the cost of the spell. If the spell
has an XP cost, add 5 gp per XP lost. </p>
    <p>Furthermore, if a spell has dangerous consequences, the
spellcaster will certainly require proof that you can and will pay for
dealing with any such consequences (that is, assuming that the
spellcaster even agrees to cast such a spell, which isn&rsquo;t
certain). In
the case of spells that transport the caster and characters over a
distance, you will likely have to pay for two castings of the spell,
even if you aren&rsquo;t returning with the caster.</p>
    <p>In addition, not every town or village has a spellcaster of
sufficient level to cast any spell. In general, you must travel to a
small town (or larger settlement) to be reasonably assured of finding a
spellcaster capable of casting 1st-level spells, a large town for
2nd-level spells, a small city for 3rd- or 4th-level spells, a large
city for 5th- or 6th-level spells, and a metropolis for 7th- or
8th-level spells. Even a metropolis isn&rsquo;t guaranteed to have a
local
spellcaster able to cast 9th-level spells.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      